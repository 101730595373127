import {useEffect, useRef, useState} from "react"
import axios from "axios"
import {
	Box,
	Button,
	CircularProgress,
	Fade, IconButton,
	Modal, Slide,
} from "@mui/material"
import {useSnackbar} from "notistack"
import ConfigurePermissions from "./ManageWorkspaceMembers/ConfigurePermissions"
import {AddMember} from "./ManageWorkspaceMembers/AddMember"
import {ListOfMembers} from "./ManageWorkspaceMembers/ListOfMembers"
import {useDispatch, useSelector} from "react-redux"
import {resetWorkspace} from "../../reducers/workspaceSlice"
import {setUserAsync} from "../../reducers/userSlice"
import {Close} from "@mui/icons-material"
import ConfigureNotePermissions from "./ManageWorkspaceMembers/ConfigureNotePermissions"
import ConfirmLeaveModal from "./ManageWorkspaceMembers/ConfirmLeaveModal"
import { selectManageWorkspaceView, VIEW } from "../../reducers/manageWorkspaceSlice"
import TransferOwnership from "./ManageWorkspaceMembers/TransferOwnership"
import {InviteAuthor} from "./ManageWorkspaceMembers/InviteAuthor"

export default function ManageWorkspaceMembers({isOpen, setIsOpen, workspace}){
	const {enqueueSnackbar} = useSnackbar()
	const dispatch = useDispatch()
	const modalRef = useRef(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isAddingNewMember, setIsAddingNewMember] = useState(false)
	const [isInvitingAuthor, setIsInvitingAuthor] = useState(false)
	const [memberToModify, setMemberToModify] = useState(null)
	const [members, setMembers] = useState([])
	const [userWorkspacePermissions, setUserWorkspacePermissions] = useState({})
	const [isNotePermission, setNotePermission] = useState(false)
	const [selectedNotes, setSelectedNotes] = useState({})
	const [submitCallback, setSubmitCallback] = useState(null)
	const [showConfirmLeave, setShowConfirmLeave] = useState(false)
	const [authorInviteLink, setAuthorInviteLink] = useState("")
	const view = useSelector(selectManageWorkspaceView)
	const isTransferOwnership = view === VIEW.TRANSFER_OWNERSHIP

	useEffect(() => {
		fetchCurrentMemberPermissions()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(isOpen){
			setUserWorkspacePermissions(workspace.permissions)
		}
	}, [isOpen, memberToModify])


	const handleClose = () => {
		handleReturnToList()
		setIsOpen(false)
	}

	const handleReturnToList = () => {
		setIsAddingNewMember(false)
		setIsInvitingAuthor(false)
		setUserWorkspacePermissions({})
		setSelectedNotes({})
		setNotePermission(false)
		setAuthorInviteLink("")
		setMemberToModify(null)
	}

	const fetchCurrentMemberPermissions = () => {
		axios
			.get(`/api/workspace/${workspace.id}/user`)
			.then(({ data }) => {
				setMembers(data)
			})
			.catch((e) => {
				console.error("RETRIEVING WORKSPACE MEMBERS & PERMISSIONS: \n", e)
				enqueueSnackbar("Failed to retrieve workspace members and permissions. Reason: ", e)
			})
			.finally(() => {setIsLoading(false)})
	}

	const leaveWorkspace = () => {
		if(showConfirmLeave) {
			setShowConfirmLeave(false)
		}
		axios
			.delete(`/api/workspace/${workspace.id}/self`)
			.then(() => {
				dispatch(setUserAsync())
				dispatch(resetWorkspace())
				handleClose()
			})
			.catch(e => {
				if(e.response.status === 515){
					enqueueSnackbar(e.response.statusText, {variant: "error"})
				} else {
					console.error("MODIFYING WORKSPACE MEMBER PERMISSIONS: \n", e)
					enqueueSnackbar(`Failed to modify workspace member's permissions. Reason: ${e}`, {variant: "error"})
				}
			})
	}

	const removeFromWorkspace = (member) => {
		axios
			.delete(`/api/workspace/${workspace.id}/user/${member.userId}`)
			.then(() => {
				fetchCurrentMemberPermissions()
			})
			.catch(e => {
				if(e.response.status === 514){
					enqueueSnackbar(e.response.statusText, {variant: "error"})
				} else {
					console.error("MODIFYING WORKSPACE MEMBER PERMISSIONS: \n", e)
					enqueueSnackbar(`Failed to modify workspace member's permissions. Reason: ${e}`, {variant: "error"})
				}
			})
	}

	

	return (
		<Modal open={isOpen} onClose={handleClose}>
			<Box
				className={"modal-container"}
				ref={modalRef}
				sx={{
					padding: "1.5rem",
					textAlign: "center",
					display: "inline-flex",
					justifyContent: "center",
					overflow: "hidden",
				}}
			>
				<IconButton
					variant={"curved-square"}
					onClick={handleClose}
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
						color: "black"
					}}
				>
					<Close />
				</IconButton>
				<Fade in={isLoading} unmountOnExit>
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<CircularProgress />
					</Box>
				</Fade>
				{/*
					List of current members
				*/}
				<Slide
					in={isTransferOwnership}
					enter
					mountOnEnter
					unmountOnExit
					direction="right"
					container={modalRef.current}
				>
					<Box>
						<TransferOwnership />

					</Box>
				</Slide>
				<Slide
					in={!isAddingNewMember && !isInvitingAuthor && !memberToModify && !isLoading && !isTransferOwnership}
					enter
					mountOnEnter
					unmountOnExit
					direction={"right"}
					container={modalRef.current}
				>
					<Box>
						<ListOfMembers
							members={members}
							setMemberToModify={setMemberToModify}
							fetchMembers={fetchCurrentMemberPermissions}
							removeMember={removeFromWorkspace}
							userWorkspacePermissions={userWorkspacePermissions}
						/>
						<br />
						<Box sx={{ display: "flex" }}>
							{ userWorkspacePermissions.admin &&
								<Button
									variant={"outlined"}
									sx={{ marginRight: "10px" }}
									onClick={() => setIsAddingNewMember(true)}
								>
									Add Member
								</Button>
							}
							{ (userWorkspacePermissions.admin && workspace.ownerIsSuperadmin) &&//TODO: add second conditional for super-admin check here
								<Button
									variant={"outlined"}
									sx={{ marginRight: "10px" }}
									onClick={() => setIsInvitingAuthor(true)}
								>
									Invite Author
								</Button>
							}
							<Button
								variant={"outlined"}
								color={"error"}
								sx={{ marginRight: "10px" }}
								onClick={() => setShowConfirmLeave(true)}
								fullWidth={!userWorkspacePermissions.admin}
							>
								Leave this workspace
							</Button>
						</Box>
					</Box>
				</Slide>
				{/*
					Add new Member
				*/}
				<Slide
					in={(isAddingNewMember && !memberToModify) && !isLoading && !isTransferOwnership}
					enter
					mountOnEnter
					unmountOnExit
					direction={"right"}
					container={modalRef.current}
				>
					<Box>
						<AddMember
							workspaceId={workspace.id}
							setIsAddingNewMember={setIsAddingNewMember}
							setNewMember={setMemberToModify}
							snackbar={enqueueSnackbar}
						/>
					</Box>
				</Slide>
				{/*
					Invite Author
				*/}
				<Slide
					in={(isInvitingAuthor && !memberToModify) && !isLoading && !isTransferOwnership}
					enter
					mountOnEnter
					unmountOnExit
					direction={"right"}
					container={modalRef.current}
				>
					<Box>
						<InviteAuthor
							workspaceId={workspace.id}
							setIsAddingNewMember={setIsInvitingAuthor}
							setNewMember={setMemberToModify}
							setNotePermissions={setNotePermission}
							setSubmitCallback={setSubmitCallback}
							setAuthorInviteLink={setAuthorInviteLink}
						/>
					</Box>
				</Slide>
				{/*
					Modify member permissions
				*/}
				<Slide
					in={(memberToModify !== null && !isInvitingAuthor) && !isLoading && !isNotePermission && !isTransferOwnership}
					enter
					mountOnEnter
					unmountOnExit
					direction={"right"}
					container={modalRef.current}
				>
					<Box>
						<ConfigurePermissions
							setSubmitCallback={setSubmitCallback}
							member={memberToModify}
							isAddingNewMember={isAddingNewMember}
							snackbar={enqueueSnackbar}
							resetModal={handleReturnToList}
							setNotePermissions={setNotePermission}
						/>
					</Box>
				</Slide>
				<Slide
					in={(memberToModify !== null) && !isLoading && isNotePermission && !isTransferOwnership}
					enter
					mountOnEnter
					unmountOnExit
					direction={"right"}
					container={modalRef.current}
				>
					<Box>
						<ConfigureNotePermissions 
							submitCallback={(notes) => {
								submitCallback(notes)
							}} 
							selectedNotes={selectedNotes} 
							setSelectedNotes={setSelectedNotes} 
							setNotePermission={setNotePermission} 
							isAddingNewMember={(isInvitingAuthor || isAddingNewMember)}
							member={memberToModify}
							authorInviteLink={authorInviteLink}
							resetModal={handleReturnToList}
						/>
					</Box>
				</Slide>
				<ConfirmLeaveModal
					openModal={showConfirmLeave}
					setOpenModal={setShowConfirmLeave}
					leaveWorkspace={leaveWorkspace}
					workspace={workspace}
				/>
			</Box>
		</Modal>
	)
}