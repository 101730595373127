import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import {selectTargetNote} from "./targetNoteSlice"

export const fetchNoteRestrictions = createAsyncThunk(
	"noteRestrictionsSlice/fetchNoteRestrictions",
	async (workspaceId, {getState, rejectWithValue}) => {
		const state = getState()
		const userId = state.user?.value?.id

		if (!workspaceId) rejectWithValue("No valid workspace id.")
		try {
			const {data: existingRestrictions} = await axios.get(`/api/workspace/${workspaceId}/user/${userId}/restrictions`)
			return existingRestrictions
		} catch (error) {
			return rejectWithValue("Failed to load note restrictions")

		}

	}
)

export const noteRestrictionsSlice = createSlice({
	name: "noteRestrictionsSlice",
	initialState: {
		allowedNoteIds: [],
		allowedEditNoteIds: [],
		isLoading: false,
		error: null
	},
	reducers: {
		updateAllowedNoteIds: (state, action) => {
			state.allowedNoteIds = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNoteRestrictions.pending, (state) => {
				state.isLoading = true
				state.error = null
			})
			.addCase(fetchNoteRestrictions.fulfilled, (state, action) => {
				const allowedNoteIds = []
				const allowedEditNoteIds = []
				for(let [noteId, type] of Object.entries(action.payload)){
					allowedNoteIds.push(+noteId)
					if(type === "edit"){
						allowedEditNoteIds.push(+noteId)
					}
				}
				state.allowedNoteIds = allowedNoteIds
				state.allowedEditNoteIds = allowedEditNoteIds
				state.isLoading = false
			})
			.addCase(fetchNoteRestrictions.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { updateAllowedNoteIds } = noteRestrictionsSlice.actions


export const selectAllowedNoteIds = state => state.noteRestrictionsSlice.allowedNoteIds
export const selectAllowedEditNoteIds = state => state.noteRestrictionsSlice.allowedEditNoteIds
export const selectIsLoading = state => state.noteRestrictionsSlice.isLoading

export const checkIfNoteCanBeEdited = createSelector(
	[selectAllowedNoteIds, selectAllowedEditNoteIds, selectTargetNote, selectIsLoading],
	(readNoteIds, editNoteIds, targetNote) => {
		if(targetNote.id === null){
			return true
		}
		if(targetNote.parentId === null) {
			//target note is root level
			return editNoteIds.includes(targetNote.id)
		} else {
			// target note is below root level, find root-level parent and check that permission
			const rootLevelParentId = targetNote.path.split(".")[0]
			return editNoteIds.includes(+rootLevelParentId)
		}
	}
)

export default noteRestrictionsSlice.reducer