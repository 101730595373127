import {
	Box,
	Button,
	Card,
	Divider,
	IconButton,
	List,
	ListItem,
	Modal,
	Stack,
	Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import NewWorkspaceForm from "./NewWorkspaceForm"
import { useDispatch, useSelector } from "react-redux"
import {
	selectWorkspace,
	setWorkspaceExclusions,
	setWorkspaceThunk,
} from "../../reducers/workspaceSlice"
import { resetSearch as resetArticleSearch } from "../../reducers/articleSearchSlice"
import { resetSearch as setAutomatedSearchArticles } from "../../reducers/automatedSearchSlice"
import { resetSearch as resetNoteArticles } from "../../reducers/noteArticlesSlice"
import { selectUser } from "../../reducers/userSlice"
import { SocketContext } from "../../context/socket.context"
import axios from "axios"
import { Close } from "@mui/icons-material"
import {resetWorkspaceSearches} from "../../reducers/workspaceSearchSlice"
import { getImportedArticleCountForWorkspace } from "../../reducers/freeTierTrackerslice"

export default function Manager({ open, setOpen }) {
	const dispatch = useDispatch()
	const socket = useContext(SocketContext)
	const user = useSelector(selectUser)
	const currentWorkspace = useSelector(selectWorkspace)
	const [createNewWorkspace, setCreateNewWorkspace] = useState(false)
	const [userWorkspaces, setUserWorkspaces] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	
	const fetchUserWorkspaces = async () => {
		setIsLoading(true)
		setError(null)
		try {
			const { data } = await axios.get("/api/workspace/userWorkspaces")
			setUserWorkspaces(data)
		} catch (e) {
			console.error("Error fetching workspaces:", e)
			setError(e)
		} finally {
			setIsLoading(false)
		}
	}
	useEffect(() => {
		if(user) {
			fetchUserWorkspaces()
		}
	},[open, user])
	const openWorkspace = (workspace) => {
		if (currentWorkspace == null) {
			socket.emit("ENTER_WORKSPACE", user.sub, workspace.id)
			dispatch(setWorkspaceThunk(workspace))
		} else if (currentWorkspace && currentWorkspace.id !== workspace.id) {
			socket.emit("LEAVE_WORKSPACE", user.sub, currentWorkspace.id)
			socket.emit("ENTER_WORKSPACE", user.sub, workspace.id)
			dispatch(resetArticleSearch())
			dispatch(setAutomatedSearchArticles())
			dispatch(resetNoteArticles())
			dispatch(resetWorkspaceSearches())
			dispatch(setWorkspaceThunk(workspace))
			localStorage.removeItem("recently-viewed-articles")
		}

		if(workspace.ownerIsFreeTier) {
			dispatch(getImportedArticleCountForWorkspace())
		}

		axios
			.get(`/api/workspace/${workspace.id}/auto-search/exclusions`)
			.then(({ data }) => {
				dispatch(setWorkspaceExclusions(data))
			})
			.catch((err) => console.error("exclusions didn't make it: ", err))

		setOpen(false)
	}

	const afterCreateNewWorkspace = (workspaceObj) => {
		socket.emit("ENTER_WORKSPACE", user.sub, workspaceObj.id)
		setCreateNewWorkspace(false)
	}

	if (isLoading || !user) {
		return <div>Loading workspaces...</div>
	}
	
	if (error) {
		return <div>Error fetching workspaces. Please refresh the page and try again.</div>
	}

	if (createNewWorkspace) {
		return (
			<Modal open={open}>
				<Box className={"modal-container"} sx={{ textAlign: "center" }}>
					<Button
						onClick={() => setCreateNewWorkspace(false)}
						sx={{ position: "absolute", right: 0, display: "inline-flex" }}
					>
						Cancel
					</Button>
					<Box>
						<Typography id="modal-modal-title" variant="h4">
							New Workspace
						</Typography>
					</Box>
					<NewWorkspaceForm afterCreate={afterCreateNewWorkspace} />
				</Box>
			</Modal>
		)
	}

	if (userWorkspaces.length) {
		return (
			<Modal open={open}>
				<Box className={"modal-container"} sx={{ textAlign: "center" }}>
					{
						//Manager is unable to be closed if currentWorkspace isn't set
						currentWorkspace !== null && (
							<IconButton
								onClick={() => setOpen(false)}
								sx={{
									position: "absolute",
									top: 0,
									right: 0,
									maxHeight: "50px",
									zIndex: 10,
								}}
							>
								<Close />
							</IconButton>
						)
					}
					<Typography id="modal-modal-title" variant="h4">
						Workspaces
					</Typography>
					<br />
					<List
						sx={{
							margin: 0,
							padding: 1,
							maxHeight: "300px",
							borderRadius: "5px",
							overflowY: "scroll",
							background: "#00000017",
						}}
					>
						{userWorkspaces.map(({ read, write, admin, workspace, ownerIsFreeTier, ownerIsSuperadmin, }) => {
							return (
								<ListItem
									key={workspace.id}
									sx={{ padding: "5px" }}
									onClick={() =>
										openWorkspace({
											...workspace,
											permissions: { read, write, admin },
											ownerIsFreeTier,
											ownerIsSuperadmin,
										})
									}
								>
									<Card elevation={2} sx={{ 
										width: "100%", 
										padding: "5px 10px",
										transition: "all .3s ease-in-out",
										cursor: "pointer",
										"&:hover" : {
											backgroundColor: "#c8ecf0"
										}}}>
										<Typography variant={"h6"} textAlign={"center"}>
											{workspace.title}
										</Typography>
										<Divider sx={{ width: "50%", margin: "auto" }} />
										<Typography variant={"subtitle1"}>
											{workspace.description}
										</Typography>
									</Card>
								</ListItem>
							)
						})}
					</List>
					<br />
					<Button
						variant={"contained"}
						sx={{ marginRight: "10px" }}
						onClick={() => setCreateNewWorkspace(true)}
					>
						Create New Workspace
					</Button>
				</Box>
			</Modal>
		)
	}

	return (
		<Modal open={open}>
			<Box className={"modal-container"} sx={{ textAlign: "center" }}>
				<Stack spacing={1}>
					<Typography id="modal-modal-title" variant="h4">
						Welcome to Refbin!
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Let's get started by creating a workspace.
					</Typography>
					<NewWorkspaceForm afterCreate={() => setOpen(false)} />
				</Stack>
			</Box>
		</Modal>
	)
}
