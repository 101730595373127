import {useState} from "react"
import {Box, Button, TextField, Typography} from "@mui/material"
import { debounce } from "lodash"
import { isEmail } from "validator"
import axios from "axios"

export function InviteAuthor({ setIsAddingNewMember, setNewMember, workspaceId, setNotePermissions, setSubmitCallback, setAuthorInviteLink }) {
	const [validEmail, setIsValidEmail] = useState(false)
	const [emailInput, setEmailInput] = useState("")

	const handleClick = () => {
		const placeHolderNewMember = {
			user: {
				firstName: "Invite",
				lastName: "Author",
				email: emailInput
			},
			read: true,
			write: true,
			admin: false,
			workspaceId,
			userId: -1,
			authorInvite: true
		}
		setIsAddingNewMember(true)
		setNotePermissions(true)
		setEmailInput("")
		setSubmitCallback(() => authorInviteSubmit)
		console.log("do we get here before we swap panels?")
		setNewMember(placeHolderNewMember)
	}

	const authorInviteSubmit = (selectedNotes = []) => {
		axios
			.post("/api/license/invite-author", {
				workspaceId,
				authorEmail: emailInput,
				allowedNotes: selectedNotes,
				read: true,
				write: true,
				admin: false
			})
			.then(({data}) => {
				setAuthorInviteLink(data)
			})
			.catch((e) => {
				console.error("FAILED TO INVITE AUTHOR: ", e)
			})
	}

	const handleChange = (e) => {
		const email = e.target.value
		setEmailInput(email)
		if (email.length >= 3) {
			setIsValidEmail(isEmail(email) === false)
		} else {
			setIsValidEmail(false)
		}
	}

	// debounce the user search
	const debouncedSearch = debounce(handleChange, 300)

	return (
		<Box>
			<Typography id="modal-modal-title" variant="h4">
				Invite Author
			</Typography>
			<br />
			<Box sx={{
				margin: "10px",
				minHeight: "200px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<TextField
					onKeyDown={(e) => e.stopPropagation()}
					fullWidth
					label="Enter an e-mail"
					variant="standard"
					onChange={debouncedSearch}
					error={validEmail}
				/>
			</Box>
			<br />
			<Box sx={{ display: "flex", gap: "1em" }}>
				<Button
					variant={"outlined"}
					color={"error"}
					onClick={() => setIsAddingNewMember(false)}
				>
					Cancel
				</Button>
				<Button
					variant={"outlined"}
					color={"primary"}
					onClick={handleClick}
					fullWidth
					disabled={emailInput.length < 3 || validEmail}
				>
					Next
				</Button>
			</Box>
		</Box>
	)
}