import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import {setUser, setUserAsync} from "./userSlice"
import {fetchCurrentInvoice} from "./stripeInvoicesSlice"

const keySubscriptionItem = {
	product: {
		active: false,
		name: "Using Key"
	},
	name: "You are using a gifted subscription",
	description:
		"Your subscription is being paid for by the owner of the License Key you used to signup for RefZoom. " +
	"If you would like to purchase your own plan, we encourage you to do so! The rest of your account will be unaffected (login/password, data, chats, etc). " +
	"To purchase your own plan, select one below and then continue to the Update Billing Details screen.",
	recurring: {interval: ""},
	price: "N/A ",
	tiers: [{unit_amount: "N/A"}],
	id: "KeySub",
	key: null,
	keyOwner: null
}
/**
 * When we modify a "product" on Stripe, we have to "archive" the old one.
 *
 * ARCHIVING A PRODUCT **DOES NOT** REMOVE IT FROM STRIPE USER DATA!
 * ARCHIVING A PRODUCT **DOES NOT** REMOVE IT FROM THE LIST OF PRODUCTS!
 *
 * instead, it sets the Stripe.[plan/price].active value to 'false' and (and this is the dumbest part) removes the cost info
 * So here we're mocking a fake price to feed into <CurrentSubscriptionCard> to nudge users to change their plan.
 */
const invalidSubscriptionItem = {
	product: {
		active: false,
		name: "Invalid Plan"
	},
	name: "Invalid Subscription Plan",
	description:
		"The subscription associated with your account has either been modified or replaced. " +
		"Please select a new plan below. If you do not, your subscription will remain valid until it's expiration date, at which point you will loose access to the app (your data will remain). " +
		"When you select a new plan, the remaining balance of your previous plan will be prorated from your next invoice.",
	recurring: {interval: ""},
	price: "N/A ",
	tiers: [{unit_amount: "N/A"}],
	id: "INVALID"
}

const missingSubscriptionItem = {
	product: {
		active: false,
		name: "Pick A Plan"
	},
	name: "Choose a Subscription Plan",
	description: "Pick a plan from below: ",
	recurring: {interval: ""},
	price: "N/A ",
	tiers: [{unit_amount: "N/A"}],
	id: "None"
}

const freeSubscriptionItem = {
	product: {
		active: true,
		name: "Free"
	},
	name: "Free",
	description: "Import up to 20 citations into a workspace.",
	recurring: {interval: ""},
	price: "N/A ",
	tiers: [{unit_amount: "N/A"}],
	id: "FreeTier"
}

export const getCustomerData = createAsyncThunk(
	"stripeDetails/getCustomerData",
	async (_, {rejectWithValue, dispatch}) => {
		dispatch(setLoadingCustomerData(true))
		try {
			const {data: customerDetails} = await axios.get("/api/subscription/")
			console.log("oops, forgot how things work... ", customerDetails)
			const isPayingForPlan = customerDetails.subscription && ["IndividualSub", "LabSub"].includes(customerDetails.subscriptionType)
			const isPayingByInvoice = customerDetails.subscription && customerDetails.subscription.collection_method === "send_invoice"
			const defaultPaymentMethod = customerDetails.customer.invoice_settings.default_payment_method

			if(isPayingForPlan && isPayingByInvoice) {
				dispatch(fetchCurrentInvoice(customerDetails.subscription.latest_invoice))
			}
			//default payment methods are saved on the customer even if the customer cancels their plan
			if(defaultPaymentMethod) {
				dispatch(getPaymentMethodData(defaultPaymentMethod))
			}
			return customerDetails
		} catch (error) {
			console.error("error fetching user customer data", error)
			return rejectWithValue(error?.response?.message || "Error fetching Stripe customer data")
		}
	}
)

export const getProductsFromStripe = createAsyncThunk(
	"stripeDetails/getProducts",
	async (_, {rejectWithValue, dispatch}) => {
		dispatch(setLoadingProducts(true))
		try {
			const {data: stripeProducts} = await axios.get("/api/subscription/product")
			return stripeProducts
		} catch (error) {
			console.error("error fetching stripeProducts", error)
			return rejectWithValue(error?.response?.message || "Error fetching Stripe customer details")
		}
	}
)

export const getPaymentMethodData = createAsyncThunk(
	"stripeDetails/getPaymentMethod",
	async (paymentMethodId, {rejectWithValue}) => {
		try {
			const {data} = await axios.get(`/api/subscription/payment-method/${paymentMethodId}`)
			return data
		} catch (e) {
			console.error("FAILED TO FETCH PAYMENT METHOD: ", e)
			rejectWithValue("Failed to fetch payment method")
		}
	}
)

export const modifySubscription = createAsyncThunk(
	"stripeDetails/modifySubscription",
	async (payload, {rejectWithValue, fulfillWithValue, getState, dispatch}) => {
		try {
			if(payload?.licenseKey) {
				const {data} = await axios.put(`/api/license/${payload.licenseKey}`)
				if(data) {
					dispatch(setUser(data))
					await dispatch(setUserAsync())
					return fulfillWithValue("Successfully signed up using a license key.")
				}
			} else {
				const state = getState()
				const customerId = state.stripeDetails.userCustomerData.id
				const newPlanId = state.stripeDetails.currentlySelectedSubscriptionId
				const quantity = state.stripeDetails.additionalLicenseKeyQuantity

				await axios.put(`/api/subscription/${customerId}`, {
					priceId: newPlanId,
					quantity
				})
				await dispatch(getCustomerData())
				await dispatch(setUserAsync())
				return fulfillWithValue("User subscription successfully updated.")
			}
		} catch (error) {
			console.error("ERROR ATTEMPTING TO MODIFY SUBSCRIPTION: ", error)
			if(error?.response?.status === 513){
				return rejectWithValue(error.response.data)
			} else {
				return rejectWithValue(error?.response?.message || "Error attempting to modify subscription, please try again or contact support")
			}
		}
	}
)

export const generateSetupIntent = createAsyncThunk(
	"stripeDetails/generateSI",
	async (_, {getState, rejectWithValue}) => {
		const {stripeDetails} = getState()
		const customerId = stripeDetails.userCustomerData.id
		try {
			const {data} = await axios.post("/api/subscription/setup-intent", { customerId })
			if(data) {
				return data
			} else {
				throw "Incorrect data returned from server"
			}
		} catch (error) {
			console.error("FAILED CREATING STRIPE SetupIntent: ", error)
			return rejectWithValue(error?.response?.message || "Unable to configure payment elements, please contact support.")
		}

	}
)

export const cancelUserSubscription = createAsyncThunk(
	"stripeDetails/cancelSubscription",
	async (_, {rejectWithValue, fulfillWithValue, getState, dispatch}) => {
		try {
			const state = getState()
			const userCustomer = state.stripeDetails.userCustomerData
			await axios.put(`/api/subscription/cancel/${userCustomer.id}`)
			//we have to shake the global state to update, or else users would be able to get back to the main app until their JWT expires...
			await dispatch(getCustomerData())
			await dispatch(setUserAsync())
			return fulfillWithValue("Successfully canceled subscription")
		} catch (error) {
			return rejectWithValue(error?.response?.message || "Error occurred while deleting subscription")
		}
	}
)

export const stripeDetails = createSlice({
	name: "stripeDetails",
	initialState: {
		customerId: -1,
		userCustomerData: null,
		userCustomerDataError: null,
		userCustomerDataSuccess: null,
		ownsSubscription: false,
		userSubscriptionData: null,
		currentlySelectedSubscriptionId: null,
		// current key quantity should only get changed on getCustomerData.fulfilled
		currentLicenseKeyQuantity: -1,
		// all other actions (adding or subtracting keys on a plan) affect 'additionalLicenseKeyQuantity'
		// once the user has set additional license keys and modified their subscription, currentLicenseKey will get updated by extra reducer
		additionalLicenseKeyQuantity: -1,
		productList: [keySubscriptionItem, invalidSubscriptionItem, missingSubscriptionItem, freeSubscriptionItem],
		loadingProducts: false,
		loadingCustomerData: false,
		updateAllowed: false,
		userSubscriptionType: null,
		userNeedsPaymentDetails: false,
		stripeClientSecret: null,
		stripeClientSecretType: null,
		setupIntentError: null,
		defaultPaymentMethod: null,
	},
	reducers: {
		addProductsFromStripe: (state, action) => {
			for(let product of action.payload) {
				const productInCurrentList = state.productList.some(currentProduct => currentProduct.id === product.id)
				const productPriceIsActive = product.product.active

				if(!productInCurrentList && productPriceIsActive) {
					state.productList.push(product)
				}
			}
		},
		setCustomerData: (state, action) => {
			const {customer} = action
			state.userCustomerData = customer
			state.customerId = customer.id
			const subscriptions = customer.subscriptions?.data
			//with no subscription, the default payment method gets written to Customer.invoice_settings
			const defaultPaymentOnInvoiceSettings = customer.invoice_settings.default_payment_method !== null
			if(defaultPaymentOnInvoiceSettings) {
				state.userNeedsPaymentDetails = false
			} else if(subscriptions.length) {
				//if the user used a card at signup, the default payment method will be on the subscription data, not the customer
				const defaultPaymentOnSubscription = subscriptions[0].default_payment_method !== null
				//this conditional can be simplified, but I prefer this as an explicit if, otherwise the logic gets harder to follow.
				if(defaultPaymentOnSubscription === false && defaultPaymentOnSubscription === false){
					state.userNeedsPaymentDetails = true
				} else {
					state.userNeedsPaymentDetails = false
				}
			//if user does not have subscription (causing Customer.subscriptions.data to be empty), then they will need payment details before they can purchase a subscription!
			} else {
				state.userNeedsPaymentDetails = true
			}
		},
		setUsingLicenseKeyData: (state, action) => {
			const completeKeySubObject = {...keySubscriptionItem}
			completeKeySubObject.key = action.licenseKey
			completeKeySubObject.keyOwner = action.licenseKeyOwner

			state.ownsSubscription = false
			state.userSubscriptionData = completeKeySubObject
		},
		setLoadingProducts: (state, action) => {
			state.loadingProducts = action.payload
		},
		setLoadingCustomerData: (state, action) => {
			state.loadingCustomerData = action.payload
		},
		setSelectedSubscriptionId: (state, action) => {
			state.currentlySelectedSubscriptionId = action.payload
		},
		setAdditionalQuantity: (state, action) => {
			state.additionalLicenseKeyQuantity = action.payload
		},
		clearUserCustomerDataError: (state) => {
			state.userCustomerDataError = null
		},
		setUserCustomerDataSuccess: (state, action) => {
			state.userCustomerDataSuccess = action.payload
		},
		clearUserCustomerDataSuccess: (state) => {
			state.userCustomerDataSuccess = null
		},
		clearSetupIntentError: (state) => {
			state.setupIntentError = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCustomerData.fulfilled, (state, action) => {
				const {subscriptionType} = action.payload
				stripeDetails.caseReducers.setCustomerData(state, action.payload)
				state.userSubscriptionType = subscriptionType

				if(subscriptionType === "None") {
					state.ownsSubscription = false
					state.currentlySelectedSubscriptionId = freeSubscriptionItem.id
					state.userSubscriptionData = freeSubscriptionItem
					state.currentLicenseKeyQuantity = 0
					state.additionalLicenseKeyQuantity = 0
				}

				if(subscriptionType === "KeySub") {
					state.ownsSubscription = false
					stripeDetails.caseReducers.setUsingLicenseKeyData(state, action.payload)
					state.currentlySelectedSubscriptionId = subscriptionType
					state.currentLicenseKeyQuantity = 0
					state.additionalLicenseKeyQuantity = 0
				}

				if(subscriptionType === "Trial") {
					state.ownsSubscription = false
					state.userSubscriptionData = action.payload.subscription
				}

				if(["IndividualSub", "LabSub"].includes(subscriptionType)) {
					state.ownsSubscription = true
					state.userSubscriptionData = action.payload.subscription
					if(action.payload.quantity) {
						state.currentLicenseKeyQuantity = action.payload.quantity
						state.additionalLicenseKeyQuantity = action.payload.quantity
					} else {
						state.currentLicenseKeyQuantity = 0
						state.additionalLicenseKeyQuantity = 0
					}
					state.currentlySelectedSubscriptionId = action.payload.subscription.plan.id
				}
				state.loadingCustomerData = false
			})
			.addCase(getProductsFromStripe.fulfilled, (state, action) => {
				stripeDetails.caseReducers.addProductsFromStripe(state, action)
				stripeDetails.caseReducers.setLoadingProducts(state, {payload: false})
			})
			.addCase(modifySubscription.rejected, (state, action) => {
				state.userCustomerDataError = action.payload
			})
			.addCase(modifySubscription.fulfilled, stripeDetails.caseReducers.setUserCustomerDataSuccess)
			.addCase(cancelUserSubscription.fulfilled, stripeDetails.caseReducers.setUserCustomerDataSuccess)
			.addCase(generateSetupIntent.fulfilled, (state, action) => {
				state.stripeClientSecretType = action.payload.object
				state.stripeClientSecret = action.payload.client_secret
			})
			.addCase(generateSetupIntent.rejected, (state, action) => {
				state.setupIntentError = action.payload
			})
			.addCase(getPaymentMethodData.fulfilled, (state, action) => {
				state.defaultPaymentMethod = action.payload
			})
	}
})

export const {
	setLoadingProducts,
	setLoadingCustomerData,
	setSelectedSubscriptionId,
	setAdditionalQuantity,
	clearUserCustomerDataError,
	setUserCustomerDataSuccess,
	clearUserCustomerDataSuccess,
	clearSetupIntentError,
} = stripeDetails.actions

export const selectProductList = (state) => state.stripeDetails.productList
export const selectLoadingProductList = (state) => state.stripeDetails.loadingProducts
export const selectLoadingCustomerData = (state) => state.stripeDetails.loadingCustomerData
export const selectCustomerData = (state) => state.stripeDetails.userCustomerData
export const selectSubscriptionData = (state) => state.stripeDetails.userSubscriptionData
export const selectAdditionalLicenseKeyQuantity = (state) => state.stripeDetails.additionalLicenseKeyQuantity
export const selectOwnsSubscription = (state) => state.stripeDetails.ownsSubscription
export const selectCurrentSelectedSubscriptionId = (state) => state.stripeDetails.currentlySelectedSubscriptionId
export const selectUserSubscriptionType = (state) => state.stripeDetails.userSubscriptionType
export const selectUserNeedsPaymentDetails = (state) => state.stripeDetails.userNeedsPaymentDetails
export const selectUserCustomerDataError = (state) => state.stripeDetails.userCustomerDataError
export const selectUserCustomerDataSuccessMessage = (state) => state.stripeDetails.userCustomerDataSuccess
export const selectStripeClientSecret = (state) => state.stripeDetails.stripeClientSecret
export const selectStripeClientSecretType = (state) => state.stripeDetails.stripeClientSecretType
export const selectSetupIntentError = (state) => state.stripeDetails.setupIntentError
export const selectCustomerId = (state) => state.stripeDetails.customerId
export const selectDefaultPaymentMethod = (state) => state.stripeDetails.defaultPaymentMethod
const selectCurrentLicenseKeyQuantity = (state) => state.stripeDetails.currentLicenseKeyQuantity // only used for internal comparisons, do not export to components

export const selectCurrentlySelectedSubscription = createSelector(
	[selectProductList, selectCurrentSelectedSubscriptionId],
	(productList, productId ) => {
		const currentPlan = productList.find(product => product.id === productId)
		return currentPlan ? currentPlan : invalidSubscriptionItem
	}
)

export const selectProductListAccountSettings = createSelector(
	[selectProductList, selectLoadingProductList, selectSubscriptionData],
	(productList, loadingProducts) => {
		if(loadingProducts) {
			return []
		} else {
			return productList.filter(refZoomPlan => refZoomPlan.product.active)
		}
	}
)

export const selectUserAllowedToUpdateSubscription = createSelector(
	[selectCurrentSelectedSubscriptionId, selectCurrentLicenseKeyQuantity, selectAdditionalLicenseKeyQuantity, selectSubscriptionData, selectUserSubscriptionType],
	(selectedSubscriptionId, currentQuantity, additionalQuantity, currentUserSubscription, subscriptionType) => {
		const noSubscription = subscriptionType === missingSubscriptionItem.id
		const usingLicense = subscriptionType === keySubscriptionItem.id
		if (noSubscription || usingLicense) {
			return selectedSubscriptionId !== subscriptionType
		} else {
			//collection method is only "send_invoice" until first payment, after that it switches to "charge automatically"
			if(currentUserSubscription.collection_method === "send_invoice"){
				return false
			}
			//user is selecting different plan or adding keys
			const currentUserSubscriptionPriceId = currentUserSubscription.plan?.id
			const planIdMismatch = selectedSubscriptionId !== currentUserSubscriptionPriceId
			const quantityMismatch = currentQuantity !== additionalQuantity

			return (planIdMismatch || quantityMismatch)
		}
	}
)

export default stripeDetails.reducer