import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js"
import { Button } from "@mui/material"
import {loadStripe} from "@stripe/stripe-js/pure"
import {StripeConfig} from "../../../../config/stripe.config"
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {
	clearSetupIntentError,
	generateSetupIntent,
	selectSetupIntentError,
	selectStripeClientSecret,
	selectStripeClientSecretType
} from "../../../../reducers/stripeDetailsSlice"
import {useEffect} from "react"
import Loading from "../../../Loading"
import {useSnackbar} from "notistack"

export function UpdateBillingDetailsForm() {
	const { enqueueSnackbar } = useSnackbar()
	const stripePromise = loadStripe(StripeConfig.perishable_key, {
		"apiVersion": "2022-11-15"
	})
	const navigate = useNavigate()
	const clientSec = useSelector(selectStripeClientSecret)
	const clientSecType = useSelector(selectStripeClientSecretType)
	const dispatch = useDispatch()
	const setupIntentError = useSelector(selectSetupIntentError)

	useEffect(() => {
		dispatch(generateSetupIntent())
	}, [])

	useEffect(() => {
		if(setupIntentError !== null) {
			enqueueSnackbar(setupIntentError, {
				variant: "error",
				autoCloseDuration: 3000,
				onClose: () => {dispatch(clearSetupIntentError())}
			})
		}
	}, [setupIntentError])


	return !clientSec
		? ( <Loading /> )
		: (
			<Elements
				stripe={stripePromise}
				options={{ clientSecret: clientSec }}
			>
				<UpdateBillingDetailsPaymentElements
					onError={enqueueSnackbar}
					navigate={navigate}
					secret={clientSec}
					type={clientSecType}
				/>
			</Elements>
		)
}

function UpdateBillingDetailsPaymentElements(props) {
	const { onError, type } = props
	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = async (event) => {
		event.preventDefault()

		if (!stripe || !elements) {
			return
		}
		let result

		if(type === "payment_intent"){
			result = await stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: `${window.location}`,
				},
			})
		} else if(type === "setup_intent") {
			result = await stripe.confirmSetup({
				elements,
				confirmParams: {
					return_url: `${window.location}`,
				},
			})
		} else {
			console.error("ERROR LOADING IN PAYMENT ELEMENTS")
			onError("Something went wrong loading in payment elements, please contact support.", {variant: "error", autoCloseDuration: 3000})
		}

		if(result.error) {
			console.error("ERROR UPDATING BILLING DETAILS: ", result.error)
			if(result.error.message){
				onError(result.error.message, {variant: "error", autoCloseDuration: 3000})
			} else {
				onError("Something went wrong processing your new payment details, please contact support.", {variant: "error", autoCloseDuration: 3000})
			}
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<Button
				disabled={!stripe}
				variant={"outlined"}
				onClick={handleSubmit}
				fullWidth
				sx={{margin: "1rem 0"}}
			>
				Update Payment Details
			</Button>
		</form>
	)
}
